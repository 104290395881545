
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/fluture.png'
import "../css/ImgInvitatie.css"
import background from "../images/h3.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" style={{ backgroundImage: `url(${background})` }}>
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">



                    <div class="card-body">
                        <h5>
                            Sunt o fetiță super tare <br />
                            Și-am gropițe în dotare. <br />
                            Pe loc, dacă mă privești<br />
                            Garantat te-ndrăgostești!<br />
                        </h5>
                        <br />
                        <h5>
                            O s-o rog pe nașa mea<br />
                            Dacă nu cumva ar vrea<br />
                            Să-mi taie din moț puțin<br />
                            Și-apoi să-l sărbătorim.<br />
                        </h5>
                        <br />
                        <h5>
                            Vă invit pe fiecare,<br />
                            De la mic până la mare<br />
                            Să petrecem împreună,<br />
                            Cum e datina străbună.<br />
                        </h5>
                        <br />
                        <h5>
                            Cu mult drag vă invit la petrecere,<br />
                            care va avea loc
                        </h5>

                        <h4>Vineri, 12 August 2022, ora 17:00</h4>
                        <h4>Restaurantul „Ciocârlia”</h4>
                        <h5>Şoseaua Balcani 36, Chișinău</h5>



                        <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                        <h4>Cu drag, Lia</h4>



                        <img id="mikey_img" src={mikey} />




                        {/** <ImgInvitatie />*/}

                        <h4 class="card-footer">Vă așteptăm cu nerăbdare, de la mic și pân la mare!</h4>
                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;