import restaurant from "./images/ciocarlia.png";

import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/h13.jpg";
import imgheader from "./images/h13.jpg";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Lia",
        familia: "fam. Juc",
        logo: logo,
        tata: "Victor",
        mama: "Victoria",
        data: "12 August 2022",
        data_confirmare: "1 august 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "jocotvictoria24@gmail.com", 
       tel: "+373 693 28 497",
       phone: "tel:+37369328497",
       viber: "viber://chat?number=%2B37369328497",
       whatsapp: "https://wa.me/+37369328497",
       messenger: "https://www.messenger.com/t/100069347755351",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Ciocârlia",
            data: "12 august 2022, vineri, ora 17:00",
            adress: "Şoseaua Balcani 36, Chișinău",
            harta: "https://g.page/zona-de-agrement-ciocarlia?share",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10867.440506628633!2d28.8279232!3d47.0822065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa843cf2f07ee5066!2sCioc%C3%A2rlia!5e0!3m2!1sro!2s!4v1656448639139!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;